import React from 'react';
import Header from '../../../components/header';
import Footer from '../../../components/footer';

const PrivacyPolicy = () => {
  return (
    <div className="container p-3 px-lg-0 py-3 py-lg-5">
      <Header />
      <div className="staticWrapper">
        <h1 className="page-title text-center my-5">Privacy Policy</h1>
        <div className="box">
          <h2 className="title mb-4">1. INTRODUCTION</h2>
          <ul className="list style-disk mb-4">
            <li>
              The domain name https://surplusapp.in and the associated mobile application titled
              ‘Surplus’ (“App”) is owned and managed by Chillar Fintech Private Limited, a company
              incorporated under the provisions of the Companies Act, 2013 and having its registered
              office at B 103, Gokul Gagan CHS LTD, Opp. Viceroy Park, Thakur Village, Kandivali E,
              Mumbai 400101 (hereinafter referred to as “Chillar” or “We” or “Us” which expression
              shall, unless it be repugnant to the context or meaning thereof, be deemed to include
              its successors, affiliates, and permitted assigns) and is also an AMFI registered
              mutual fund distributor to facilitate web based transactions of investments by the end
              customers in ICICI Prudential Liquid Fund – Growth scheme of ICICI Prudential Mutual
              Fund through its investment manager ICICI Prudential Asset Management Company Limited
              (“IPRUAMC”).
            </li>
            <li>
              Chillar is committed to respecting your online privacy and recognizes your need for
              appropriate protection and management of any information you share with Chillar on the
              App. This privacy policy (“Policy”) explains how Chillar will collect, use, share and
              process information in relation to the services provided on the App.
            </li>
            <li>
              This Policy shall be deemed to be incorporated into the End User License Agreement
              (“EULA”) and shall be read in addition to the EULA. In the event of any conflict
              between this Policy and the EULA, the interpretation placed by Chillar shall be final
              and binding on you.
            </li>
            <li>
              This Policy is being framed in view of the Information Technology Act, 2000 read with
              Regulation 4 of the Information Technology (Reasonable Security Practices and
              Procedures and Sensitive Personal Data or Information) Rules, 2011 and Regulation 3(1)
              of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code)
              Rules, 2021.
            </li>
            <li>
              By accepting this Policy, you understand and agree to the collection, use, sharing and
              processing of personal information as described herein.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">2. SCOPE</h2>
          <ul className="list style-disk mb-4">
            <li>
              This Policy is an electronic record in the form of an electronic contract formed under
              the Information Technology Act, 2000 and the rules made thereunder. This Policy does
              not require any physical, electronic or digital signature.
            </li>
            <li>
              {' '}
              By accessing or using the App or giving us your information or otherwise clicking to
              accept this Policy, if and when prompted on the App, you undertake that you have the
              capacity to enter into a legally binding contract vide. this Policy, which constitutes
              a legally binding document between you and Chillar under applicable law, in
              particular, the Indian Contract Act, 1872. Chillar will collect and process your
              personal and third-party data carefully, only for the purposes described in this
              Policy and only to the extent necessary as defined herein and within the scope of the
              applicable legal regulations. This Policy seeks to ensure that any personal
              information or third-party information handled by Chillar is managed in a way that is
              ethical, compliant and adheres to best industry practices.
            </li>
            <li>
              {' '}
              Please read the terms and conditions of this Policy carefully, before accessing or
              using the App. By accessing or using the App including its services or otherwise
              clicking to accept this Policy, if and when prompted on the App, you agree to the
              terms of this Policy.
            </li>
            <li>
              {' '}
              This Policy describes the types of information Chillar collects, why and how Chillar
              uses the information, with whom Chillar shares it, and the choices you can make about
              Chillar’s use of the information. This Policy also describes the measures Chillar
              takes to protect the security of the information and how you can contact Chillar about
              its privacy practices.
            </li>
            <li>
              {' '}
              This Policy describes Chillar’s current data protection policies and practices and may
              be amended/updated from time to time. Chillar will notify you at least once in a year,
              or whenever there is a change in the Policy, by email or by posting a conspicuous
              notice on the App regarding its then applicable data protection policies. The Policy
              shall come to effect from the date of such update, change or modification. It is
              recommended that you regularly check this Policy to apprise yourself of any updates.
              Your continued use of the App or provision of data or information thereafter will
              imply your unconditional acceptance of such updates to this Policy.
            </li>
          </ul>
        </div>

        <div className="box">
          <h2 className="title mb-4">3. CONSENT</h2>
          <ul className="list style-disk mb-4">
            <li>
              {' '}
              By mere use of the App, you expressly consent to Chillar’s use and disclosure of your
              Personal Information (as defined below) and third-party information in accordance with
              this Policy. If you do not agree with the terms of this Policy, please do not use the
              App.
            </li>
            <li>
              {' '}
              In case you wish to avail any or all of the services provided by Chillar on the App,
              you are required to register on the App and thereafter access the App using the login
              credentials provided by you at the time of registration as set out in the EULA (“Login
              Credentials”). You hereby explicitly agree that your use of the Login Credentials
              shall be governed by the EULA read with the terms of this Policy.
            </li>
          </ul>
        </div>

        <div className="box">
          <h2 className="title mb-4">4. TYPES OF INFORMATION </h2>
          <ul className="list style-disk mb-4">
            <li>
              {' '}
              Personal Information is defined as any information that relates to a natural person,
              which, either directly or indirectly, in combination with other information available
              or likely to be available with a body corporate, is capable of identifying such
              person.
            </li>

            <li>
              {' '}
              Personal Information: Personal Information means any information that may be used to
              identify an individual, including, but not limited to, the first and last names,
              telephone number, e-mail address, or any other contact information. Chillar limits the
              collection of Personal Information to that which is necessary for its intended
              purpose.
            </li>
            <li>
              {' '}
              Financial Information: Financial Information means such category of Personal
              Information that is required to be shared by Chillar with PRUAMC and such other third
              parties as may be engaged by Chillar and is required be retained in accordance with
              the regulations and guidelines issued by the Reserve Bank of India (“RBI”) from time
              to time and other applicable laws and includes without limitation, bank account
              details, credit information, PAN details, Aadhar card or any other Know Your Client
              (“KYC”) details (hereinafter referred to as “Financial Information”).
            </li>
            <li>
              {' '}
              Non-Personal Information: Non-personal information means information that does not
              specifically identify an individual, but includes information from you, such as your
              browser type, your Internet Service Provider (ISP), operating system and your Internet
              Protocol (IP) address. Chillar may gather any non-personal information regarding how
              many people visit the App, their IP address, browser types and versions, time zone
              settings and locations, operating systems, applications installed on your device,
              device ID, device manufacturer and type, device, connection information, screen
              resolution, usage statistics, default communication applications and other technology
              on the devices you use to access the App, maximum products purchased by a user,
              analysis of the spending and investment habits of a user and shall include all such
              Personal Information collected and stored by Chillar that undergoes the process of
              de-identification and is no more identifiable to you or any natural person(hereinafter
              referred to as “Non-Personal Information”). Chillar may also collect Non- Personal
              Information that you voluntarily provide, such as information included in response to
              a questionnaire or a survey conducted by Chillar.
            </li>
            <li>
              {' '}
              Usage Information: Usage Information includes without limitation all data and
              information collected automatically through the App (or through the third party
              analytics service providers), by use and access of the App in the nature of system
              administrative data, statistical and demographical data, and operational information
              and data generated by or characterizing use of the App including without limitation
              Non-Personal Information, cookies, App traffic, time spent on the App, number of
              visits to the App and other similar information and behaviour indicating the mode and
              manner of use of the App (hereinafter referred to as the “Usage Information”).
            </li>
            <li>
              {' '}
              Personal Information, Business Information, Non-Personal Information and Usage
              Information hereinafter shall be referred to as “Information”.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">5. COLLECTION OF INFORMATION </h2>
          <ul className="list style-disk mb-4">
            <li>
              Chillar may collect information from you when you (a) register on the App; (b) update
              or change your Bank Account (as defined in the EULA) details on the App; (c) provide
              approval for E-Mandate (as defined in the EULA) through the App; (d) complete KYC
              verification, undertaken by IPRUAMC or such other third-party service provider as may
              be engaged by Chillar, through the App, (e) provide access to Chillar to your emails
              for providing the services in accordance with the EULA, (f) voluntarily participate in
              campaigns conducted by Chillar on the App or respond to questionnaires published by
              Chillar on the App (if any); (f) voluntarily complete a survey or provide feedback in
              relation to the services provided on the App; and (f) carry out any other transactions
              on the App.
            </li>
            <li>
              {' '}
              You hereby acknowledge and agree that all Information is provided by you to Chillar or
              IPRUAMC, voluntarily and the Information provided by you is not subject to any undue
              influence.
            </li>
            <li>
              {' '}
              Chillar may use cookies to monitor the App usage including, without limitation, to
              provide useful features to simplify your experience when you return to the App, like
              remembering your login id and to deliver relevant content based on your preferences,
              usage patterns and location.
            </li>
            <li>
              {' '}
              Chillar may also collect Non-Personal Information based on your spending and
              investments, browsing activity and in relation to your use or access to the App like
              your Internet Protocol address, your Operating System, your prior search results etc.,
              which may or may not be publicly accessible.
            </li>
            <li>
              {' '}
              Information collected by Chillar from a particular browser or device may be used with
              another computer or device that is linked to the browser or device on which such
              information was collected.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">6. USE OF INFORMATION</h2>
          <ul className="list style-disk mb-4">
            <li>
              {' '}
              Chillar uses the Information you provide to (a) manage your account; (b) fulfil your
              requests for the services offered on the App; (c) respond to your inquiries about its
              offerings and the transactions carried out on the App; (d) provide you with
              information about services available on the App and offer you other products and
              services that Chillar believes may be of interest to you; (e) resolve any glitches on
              the App including addressing any technical problems; (f) improve the services and
              content on the App and your experience of navigating through the App and carrying out
              transactions on the App and; (f) manage Chillar’s relationship with you.
            </li>
            <li>
              {' '}
              Chillar may use the Information to monitor your use of the services and may review and
              analyse the Information provided by you to provide you with customized service.
            </li>
            <li>
              {' '}
              Chillar may use your Non-Personal Information or Usage Information for internal
              business purposes, such as data analysis, research, developing new products and/or
              features, enhancing and improving existing products and services and identifying usage
              trends.
            </li>
            <li>
              {' '}
              Subject to and in accordance with applicable laws, Chillar has the right to use your
              Information for the purpose of conducting promotional/marketing related activities on
              the App, including but not limited to, using your Personal Information for making
              posters/banners to promote Chillar’s services.
            </li>
            <li>
              {' '}
              When you send an email message or otherwise contact Chillar through the App, Chillar
              may use the Information provided by you to respond to your communication by way of
              messages on the App, Short Message Service (SMS), email or any other communication
              channels that Chillar may deem fit. Chillar may also archive such Information and/or
              use it for future communications with you to inform you regarding updates,
              newsletters, offers, new services and promotions.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">7. INFORMATION SHARING</h2>
          <ul className="list style-disk mb-4">
            <li>
              Chillar maintains your Information in electronic form on its devices and on the
              equipment of Chillar’s employees. The Information is made accessible to employees,
              agents or partners and third-parties only on a need-to-know basis.
            </li>
            <li>
              {' '}
              Chillar may share your Information with IPRUAMC, and such other third party as may be
              engaged by Chillar for the purpose of undertaking the KYC verification process and for
              the purpose of providing services on the App to you, to the extent required for the
              provision of services in accordance with the provisions laid down in the EULA.
            </li>
            <li>
              Chillar does not rent, sell, or share Information with other people or with other
              non-affiliated entities, except with your consent or to provide services you have
              requested for or under the following circumstances:
              <ul className="list style-none mt-4 mb-4">
                <li>
                  (a) Chillar may share Information with Chillar’s partners, and affiliates to
                  contact you via email, phone, or otherwise for the provision of services being
                  availed by you on the App.
                </li>
                <li>
                  (b) Chillar may engage third party vendors and/or contractors, other than Chillar
                  Partners, to perform certain support services, who may have limited access to
                  Information.
                </li>
                <li>
                  (c) Chillar may rent, sell or share Non-Personal Information or Personal
                  Information in an aggregate form after it undergoes the process of
                  de-identifications and is no more identifiable to you, with any third party.
                </li>
                <li>
                  (d) Chillar may share Information with government authorities in response to
                  subpoenas, court orders, or other legal process; to establish or exercise legal
                  rights; to defend against legal claims; or as otherwise required by law. This may
                  be done in response to a law enforcement agency's request.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">8. THIRD PARTY SERVICE PROVIDERS </h2>
          <ul className="list style-disk mb-4">
            <li>
              {' '}
              In addition to IPRUAMC, Chillar may engage other third party vendors and/or
              contractors to perform certain support services, including, without limitation,
              fulfilment of your service requests, undertaking the KYC verification process,
              software maintenance services, advertising and marketing services, web hosting
              services and such other related services which are required by Chillar to provide its
              services efficiently. These third parties may have limited access to Information. If
              they do, this limited access is provided so that they may perform these tasks for
              Chillar and they are not authorized by Chillar to otherwise use or disclose
              Information, except to the extent required by law. Chillar does not make any
              representations concerning the privacy practices or policies or terms of use of such
              websites, nor does it control or guarantee the accuracy, integrity, or quality of the
              information, data, text, software, music, sound, photographs, graphics, videos,
              messages or other materials available on such websites. The inclusion or exclusion
              does not imply any endorsement by Chillar of the website, the website's provider, or
              the information on such website.
            </li>
            <li>
              {' '}
              The App may contain links and interactive functionality interacting with the websites
              of third parties. Chillar is not responsible for and has no liability for the
              functionality, actions, inactions, privacy settings, privacy policies, terms, or
              content of any such websites. Before enabling any sharing functions to communicate
              with any such websites or otherwise visiting any such websites, Chillar strongly
              recommends that you review and understand the terms and conditions, privacy policies,
              settings, and information-sharing functions of each such third- party websites.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">9. DISCLOSURE TO ACQUIRERS</h2>
          <p className="text">
            Chillar may disclose and/or transfer Information to an investor, acquirer, assignee or
            other successor entity in connection with a sale, merger, or reorganization of all or
            substantially all of Chillar’s equity, business or assets.
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">10. CONTROL OVER YOUR PERSONAL INFORMATION </h2>
          <ul className="list style-disk mb-4">
            <li>
              {' '}
              You have the right to withdraw your consent at any point, provided such withdrawal of
              the consent is intimated to Chillar in writing through an email at{' '}
              <label> rajkumar@surplusapp.in</label> in requesting the same. If you at any time wish
              to rectify your Personal Information including Financial Information, you may write to
              Chillar as per paragraph 11 of this Policy.
            </li>
            <li>
              {' '}
              In the event that you wish to prevent any further access to your emails by Chillar for
              the provision of the services on the App, you may do so by deletion/deactivation of
              your Account (as defined in EULA) in accordance with the procedure laid down in the
              EULA.
            </li>
            <li>
              {' '}
              Once you withdraw your consent to share the Personal Information, collected by Chillar
              and/or delete/deactivate your Account (as applicable), Chillar shall have the option
              not to fulfil the purposes for which the said Personal Information was sought and
              Chillar may restrict you from using the services on the App and/or the App itself.
            </li>
            <li>
              {' '}
              The Financial Information shared by you with Chillar or IPRUAMC shall be retained and
              shared with such third parties as may be required under applicable law.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">11. RECTIFICATION/CORRECTION OF PERSONAL INFORMATION </h2>
          <ul className="list style-disk mb-4">
            <li>
              {' '}
              You shall have the right to review your Personal Information, including Financial
              Information (as applicable) submitted by you on the App and to modify or delete any
              Personal Information or Financial Information provided by you directly on the App. You
              hereby understand that any such modification or deletion may affect your ability to
              use the App. Further, it may affect Chillar’s ability to provide its services to you.
            </li>
            <li>
              {' '}
              Chillar reserves the right to undertake KYC and/or verify and authenticate your
              identity and your account information in any manner in order to ensure accurate
              delivery of services through the App. Access to or correction, updating or deletion of
              your Personal Information may be denied or limited by Chillar if it would violate
              another person’s rights and/or is not otherwise permitted by applicable law.
            </li>
            <li>
              {' '}
              If you need to update or correct your Personal Information that Chillar may have
              collected to offer you personalized services and offers, you may send updates and
              corrections to Chillar at
              <label> rajkumar@surplusapp.in</label> citing the reason for such rectification of
              Personal Information). Chillar will take all reasonable efforts to incorporate the
              changes within a reasonable period of time.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">12. TERM OF STORAGE OF PERSONAL INFORMATION </h2>
          <ul className="list style-disk mb-4">
            <li>
              Subject to Clause 12.2 below, Chillar shall store your Personal Information at least
              for such period as may be required and permitted by law or for a period necessary to
              satisfy the purpose for which the Personal Information has been collected. These
              periods vary depending on the nature of the information and your interactions with
              Chillar.
            </li>
            <li>
              {' '}
              Chillar shall store your Financial Information as may be requested by IPRUAMC and/or
              as may be required under applicable law.
            </li>
            <li>
              {' '}
              Chillar may store Non-Personal Information and Usage Information received from you
              till such time it requires provided such storage and retention is in accordance with
              applicable law.
            </li>
            <li>
              {' '}
              You agree that you will not submit any false information or any illegal or damaging
              content to the App.
            </li>
            <li>
              {' '}
              Chillar reserves the right to terminate access to or the ability to interact with the
              App in response to any concerns Chillar may have about false, illegal, or damaging
              content, or for any other reason, in its sole discretion.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">13. COOKIES</h2>
          <ul className="list style-disk mb-4">
            <li>
              {' '}
              Chillar uses cookies and/or other tracking technologies to distinguish you from other
              users of the services and to remember your preferences on the App. This helps Chillar
              to provide you with a good experience when you use the services on the App and also
              allows Chillar to improve such services. Cookies are text files Chillar places in your
              mobile phone, tablet or other devices to store your preferences. Cookies, by
              themselves, do not tell Chillar your e- mail address or other personally identifiable
              information unless you choose to provide this information to Chillar by, for example,
              registering on the App. They are designed to hold a marginal amount of data specific
              to a particular user. However, once you choose to furnish the App with personally
              identifiable information, this information may be linked to the data stored in the
              cookie. Chillar uses cookies to understand App usage and to improve the content and
              offerings on the App. For example, Chillar may use cookies to personalize your
              experience on the App (e.g., to recognize you by name when you return to the App) and
              save your password in password-protected areas. Chillar may also use cookies to offer
              you products, programs, or services. Cookies may be placed on the App by third-parties
              as well, the use of which Chillar does not control.
            </li>
            <li>
              Session cookies are automatically deleted from your hard drive once a session ends,
              and most cookies are session cookies. You may decline the cookies, however, if you
              decline the cookies, you may be unable to use certain features on the App and you may
              be required to re-enter your password frequently. You may opt to leave the cookie
              turned on.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">14. PROTECTION OF INFORMATION </h2>
          <ul className="list style-disk mb-4">
            <li>
              Chillar has taken adequate measures to protect the security of Information and to
              ensure that your choices for its intended use are honoured. Chillar takes robust
              precautions to protect your data from loss, misuse, unauthorized access or disclosure,
              alteration, or destruction.
            </li>
            <li>
              Chillar considers the confidentiality and security of your information to be of utmost
              importance. It therefore uses industry standards, and physical, technical and
              administrative security measures to keep Information confidential and secure and
              Chillar will not share your Information with third parties, except as otherwise
              provided in this Policy. Please be advised that, however, while Chillar strives to
              protect Information and privacy, Chillar cannot guarantee or warranty its absolute
              security when Information is transmitted over the internet into the App. Chillar will
              periodically evaluate this necessity considering your privacy and our relation while
              keeping the applicable legislation in mind.
            </li>
            <li>
              {' '}
              Access to your account on the App is via. your Login Credentials which are password
              protected and this helps to secure your account information. You are solely
              responsible for maintaining the confidentiality of your Login Credentials. To ensure
              safety of your Information, you are advised against sharing your Login Credentials
              with anyone. If you suspect any unauthorized use of your account, you must immediately
              notify Chillar. You shall be liable to indemnify Chillar for any loss suffered by
              Chillar due to such unauthorized use of your account.
            </li>
            <li>
              {' '}
              For any loss or theft of Information, due to unauthorized access to your device
              through which you use the App or other reasons solely attributable to you, Chillar
              shall not be held liable or responsible under any circumstance whatsoever. Further,
              Chillar shall not be responsible for any breach of security or for any actions of any
              third parties or events that are beyond Chillar’s reasonable control including but not
              limited to acts of government, computer hacking, unauthorised access to computer data
              and storage device, computer crashes, breach of security and encryption, poor quality
              of Internet service or telephone service of the user, etc.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">15. MINOR & USAGE ON BEHALF OF ANOTHER PERSON</h2>
          <p className="text">
            Chillar does not intend to attract anyone under the relevant age of consent to enter
            into binding legal contracts under the laws of their respective jurisdictions. Chillar
            does not intentionally or knowingly collect Personal Information through the App from
            anyone under that age.
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">16. LIMITATION OF LIABILITY</h2>
          <ul className="list style-disk mb-4">
            <li>
              Chillar shall not be liable to you for any loss of profit, production, anticipated
              savings, goodwill or business opportunities or any type of direct or indirect,
              incidental, economic, compensatory, punitive, exemplary or consequential losses
              arising out of performance or non-performance of its obligations under this Policy.
            </li>
            <li>
              {' '}
              Chillar is not responsible for any actions or inactions of any third parties that
              receive your Information.
            </li>
            <li>
              {' '}
              Notwithstanding anything contained in this Policy or elsewhere, Chillar shall not be
              held responsible for any loss, damage or misuse of your Information, if such loss,
              damage or misuse is attributable to a Force Majeure Event. The term “Force Majeure
              Event” shall mean any event that is beyond Chillar’s reasonable control and shall
              include, without limitation, sabotage, fire, flood, explosion, acts of God, civil
              commotion, strikes, lockouts or industrial action of any kind, riots, insurrection,
              war, acts of government, computer hacking, civil disturbances, unauthorised access to
              computer data and storage device, computer crashes, breach of security and encryption,
              pandemic or national/state lockdown due to any reason and any other similar events not
              within Chillar’s control and which Chillar is not able to overcome.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">17. OPT-OUT</h2>
          <p className="text">
            Once you register as a user on the App, you may receive communication, including but not
            limited to messages on the App, Short Message Service (SMS) and phone calls from Chillar
            on the registered mobile number and e-mails on your registered e-mail address. These
            messages, e-mails and calls could relate to your registration, transactions that you
            carry out through the App and promotions that are undertaken by Chillar. You have the
            option to 'opt- out' of all newsletters and other general email marketing communications
            from Chillar by way of links provided at the bottom of each mailer. Chillar respects
            your privacy and in the event that you choose to not receive such mailers, Chillar shall
            take all adequate steps to remove you from such lists. However, you will not be able to
            opt-out of receiving administrative messages, customer service responses or other
            transactional communications.
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">18. CHANGES TO THIS POLICY</h2>
          <p className="text">
            Chillar reserves the right to update, change or modify this Policy at any time. The
            Policy shall come to effect from the date of such update, change or modification.{' '}
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">19. GOVERNING LAW AND DISPUTE RESOLUTION</h2>
          <p className="text">
            This Policy shall be governed by and interpreted and construed in accordance with the
            laws of India. The place of jurisdiction shall exclusively be in Mumbai, Maharashtra. In
            the event of any dispute arising out of this Policy the same shall be settled by a
            binding arbitration conducted by a sole arbitrator, appointed jointly by both parties
            and governed by the Arbitration and Conciliation Act, 1996. The venue and seat of
            arbitration shall be Mumbai, Maharashtra.
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">20. GRIEVANCE REDRESSAL MECHANISM</h2>
          <p className="text">
            In accordance with the Information Technology Act, 2000 and Rules made thereunder, the
            name and contact details of the Grievance Officer are provided below:
          </p>
          <p className="text">
            Name: Mr. Rajkumar Desai <br />
            Email: rajkumar@surplusapp.in
            <br />
            Phone No: <label> 8879476576</label>
          </p>
          <p className="text">
            For registering your complaint, please write to the Grievance Officer at the
            above-mentioned email address in relation to any violation of this Policy or the
            Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules,
            2021. The Grievance Officer shall redress the complaint in accordance with the
            provisions of the Information Technology Act, 2000 and Rules made thereunder.
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">21. CONTACT DETAILS</h2>
          <p className="text">
            If you have questions or concerns about this Policy, please contact Chillar at the
            following email address:<label> rajkumar@surplusapp.in</label>.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default PrivacyPolicy;
