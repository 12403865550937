import React from 'react';
import securityImage from '../../images/security-image.png';
import './styles.scss';

const Chip = ({ text }) => {
  return (
    <div className="security-chip">
      <p>{text}</p>
    </div>
  );
};
const Security = () => {
  const chipData = ['Simple', 'Safe', 'Secure'];
  return (
    <div className="security">
      <div>
        <h3 className="security-head">
          <span className="security-head-span">Security</span> to live for
        </h3>

        <p className="security-para">
          We use state-of-the-art AES-256 standard to keep all your data safe, encrypted and secure
        </p>

        <div className="security-chip-box">
          {chipData.map((e, index) => {
            return <Chip text={e} key={index} />;
          })}
        </div>
      </div>
      <img src={securityImage} />
    </div>
  );
};

export default Security;
