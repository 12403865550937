import s1 from '../../images/slider/linkAccountScreen.JPG';
import s2 from '../../images/slider/expensePie.png';
import s3 from '../../images/slider/billsAndRecharge.png';
import s4 from '../../images/slider/dashboard.png';
export const aboutMapper = {
  1: s1,
  2: s2,
  3: s3,
  4: s4,
};
