import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeScreen from '../home';
import PrivacyPolicy from '../static/privacy-policy';
import LicenceAgreement from '../static/licence-agreement';
import PartnerPage from '../partner';
import ContactPage from '../contact';

const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/licence-agreement" element={<LicenceAgreement />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/" element={<HomeScreen />} />
        <Route path="/partner" element={<PartnerPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesApp