import React, { useState, useEffect } from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import logo from '../../images/logo.svg'
import i3 from '../../images/icons/public.png'
import './styles.scss'
import { postAPI } from '../../libs/api'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
const ContactPage = () => {
    const [formData, setFormData] = useState({})
    const [isLoading, setLoading] = useState(false)
    const handleChange = (key, value) => {
        setFormData({ ...formData, [key]: value })
    }
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = async () => {
        setLoading(true)
        const token = await executeRecaptcha();
        const apiData={
            ...formData,
            token: token
        }
        postAPI(`/user/contact-us`, apiData)
            .then(res => {
                setFormData({})
                document.getElementById('contact-form').reset()
                setLoading(false)
                alert("Thanks, we'll contact you soon.")
                window.location.reload();
            }).catch(err => {
                setLoading(false)
                document.getElementById('contact-form').reset()
                setFormData({})
                alert("Some Error Occured, Please Try again")
                console.log(err)
            })
    }
    return (
        <div className='contact-page container p-3 px-lg-0 py-3 py-lg-5'>
            <Header isPartner={true} />
            <div className='contact-page-content'>
                <div>

                    <img className='contact-page-logo' alt="brand-logo" src={logo} width={150} />
                    <h1 className='contact-page-head'>Want to speak with us?</h1>
                    <div className='contact-page-info'>
                        <div className='contact-page-info-card'>
                            <img alt='icon' src={i3} />
                            <p>Fill out this form and someone from our team will reach out to you at the earliest</p>
                        </div>
                    </div>
                </div>
                <div>
                    <form id='contact-form' className='contact-page-form' onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}>
                        <h3 className='contact-page-form-head'>Help us to help you </h3>
                        <div className='contact-page-form-row'>
                            <label className='contact-page-form-label'>Your Name*</label>
                            <input
                                className='contact-page-form-input'
                                required={true}
                                value={formData.name}
                                onChange={(e) => handleChange('name', e.target.value)} />
                        </div>
                        <div className='contact-page-form-row'>
                            <label className='contact-page-form-label'>Your Number*</label>
                            <input
                                className='contact-page-form-input'
                                required={true}
                                value={formData.mobileNumber}
                                onChange={(e) => handleChange('mobileNumber', e.target.value)} />
                        </div>
                        <div className='contact-page-form-row'>
                            <label className='contact-page-form-label'>Email*</label>
                            <input
                                className='contact-page-form-input'
                                required={true}
                                value={formData.email}
                                onChange={(e) => handleChange('email', e.target.value)} />
                        </div>
                        <div className='contact-page-form-row'>
                            <label className='contact-page-form-label'>Add Note*</label>
                            <input
                                className='contact-page-form-input'
                                required={true}
                                value={formData.note}
                                onChange={(e) => handleChange('note', e.target.value)} />
                        </div>

                        <button className='contact-page-form-btn' type='submit'>
                            {isLoading ? 'loading...' : 'Submit'}
                        </button>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ContactPage
