import React from 'react';
import Header from '../../../components/header';
import Footer from '../../../components/footer';

const LicenceAgreement = () => {
  return (
    <div className="container p-3 px-lg-0 py-3 py-lg-5">
      <Header />
      <div className="staticWrapper">
        <h1 className="page-title text-center my-5">END USER LICENSE AGREEMENT</h1>
        <div className="box">
          <h2 className="title mb-4">1. INTRODUCTION</h2>
          <ul className="list style-disk mb-4">
            <li>
              THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF THE INFORMATION TECHNOLOGY ACT, 2000
              AND RULES MADE THEREUNDER. THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM
              AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.
            </li>
            <li>
              THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF RULE 3(1) OF THE
              INFORMATION TECHNOLOGY (INTERMEDIARY GUIDELINES AND DIGITAL MEDIA ETHICS CODE) RULES,
              2021 THAT REQUIRES PUBLISHING THE RULES AND REGULATIONS, PRIVACY POLICY AND USER
              AGREEMENT FOR ACCESS OR USAGE OF THE APP.
            </li>
            <li>
              The domain name https://surplusapp.in and the associated mobile application titled
              ‘Surplus’ (“App”) is owned and managed by Chillar Fintech Private Limited, a company
              incorporated under the provisions of the Companies Act, 2013 and having its registered
              office at B 103, Gokul Gagan CHS LTD, Opp. Viceroy Park, Thakur Village, Kandivali E,
              Mumbai 400101 (hereinafter referred to as “Chillar” or “We” or “Us” which expression
              shall, unless it be repugnant to the context or meaning thereof, be deemed to include
              its successors, affiliates, and permitted assigns) and is also an AMFI registered
              mutual fund distributor to facilitate web based transactions of investments by the end
              customers in ICICI Prudential Liquid Fund – Growth scheme of ICICI Prudential Mutual
              Fund through its investment manager ICICI Prudential Asset Management Company Limited
              (“IPRUAMC”) (“Mutual Fund Scheme”).
            </li>
            <li>
              Please read this End User License Agreement (“EULA”) carefully, as it contains the
              terms and conditions governing your use of the App and any content such as text, data,
              information, software, graphics, audio, video or photographs etc. that Chillar may
              make available through the App and any services that Chillar may provide through the
              App. In order to use the App, you must first agree to this EULA. By accessing,
              browsing or using the App or otherwise clicking to accept this EULA, if and when
              prompted on the App, you are agreeing to this EULA and concluding a legally binding
              contract with Chillar. Please do not access or use the App or otherwise click to
              accept this EULA, if and when prompted on the App, if you do not agree with any of the
              terms provided below or are unable to be bound by them. As a condition of your access
              to and use of the App, you hereby agree that you will comply with this EULA and all
              applicable laws and regulations when using the App.
            </li>
            <li>
              Chillar reserves the right, at its sole discretion, to change, modify, add or remove
              portions of this EULA, at any time and will notify you at least once in a year, or
              whenever there is a change in the EULA, by email or by posting a conspicuous notice on
              the App. Your continued use of the App following the posting of changes shall be
              deemed to mean that you accept and agree to the revisions made to the EULA. As long as
              you comply with this EULA, Chillar grants you a personal, revocable, non-exclusive,
              non-transferable, limited privilege to access and use the App.
            </li>
            <li>
              You should read the EULA and access and read all further linked information, if any,
              referred to in the EULA, as such information contains further terms and conditions
              that apply to you as a user of the App. Such linked information including but not
              limited to Chillar’s privacy policy (“Policy” or “Privacy Policy”) is hereby
              incorporated by reference into this EULA.
            </li>
            <li>
              If you are unwilling or unable to be legally bound by this EULA, please do not use the
              App or otherwise click to accept this EULA, if and when prompted on the App. You
              cannot accept this EULA if: (a) you are not lawfully entitled to use the App; or (b)
              if you are not of legal age to form a binding agreement with Chillar.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">2. MEMBERSHIP ELIGIBILITY</h2>
          <p className="text">
            Use of the App is available only to natural persons resident in India who can enter into
            legally binding contracts under the Indian Contract Act, 1872. Persons who are
            “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including
            minors, un-discharged insolvents and persons of unsound mind are not eligible to use the
            App. Any person under the age of 18 shall not register on App and shall not transact on
            or use the App. If a minor wishes to use or transact on the App, such use or transaction
            may be made by the minor’s legal guardian or parents on the App. Chillar reserves the
            right to terminate any person’s membership in accordance with the terms of this EULA
            and/or refuse to provide such person with access to the App if it is brought to the
            notice of Chillar or if Chillar discovers for itself that such person is not eligible to
            use the App.
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">3. ACCEPTANCE OF EULA</h2>
          <ul className="list style-disk mb-4">
            <li>
              In order to use the App, you must first agree to this EULA. You can accept the EULA
              by:
            </li>
            <ul className="list style-none mb-4 mt-4">
              <li>
                (a) Signing up with Chillar and logging onto Chillar by using the Login Credentials
                (defined below);
              </li>
              <li>
                (b) Actual usage of the App. In this case, you understand and agree that Chillar
                will treat your use of the App as acceptance of the EULA from that point onwards; or
              </li>
              <li>(c) By clicking to accept this EULA, if and when prompted on the App.</li>
            </ul>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">4. OPENING AN ACCOUNT</h2>
          <ul className="list style-disk mb-4">
            <li>
              In order to use the App, you will have to create an account by signing up on the App
              (“Account”), which can be done by providing/ using the following: (a) your mobile
              number with One Time Password (OTP); or (b) such other login credentials that are
              adopted by Chillar from time to time and duly intimated to you. Based on the
              information provided by you including personal information, your Account will be
              activated once the requisite Know Your Client (KYC) verification is undertaken by
              IPRUAMC or such other third-party service provider as may be engaged by Chillar for
              the purpose of KYC verification in accordance with applicable law, regulations and
              guidelines of any regulatory / government agencies or authorities from time to time.
              Chillar shall share your financial information or any other information required for
              the purpose of KYC verification undertaken by IPRUMAMC or such other service provider
              engaged by Chillar. Upon completion of verification, your Account will be ready for
              use. You shall not transfer or share your Account information with anyone or create
              more than one Account.
            </li>
            <li>
              <p className="text"> While signing-up on the App, you shall not:</p>
              <ul className="list style-none mb-4">
                <li>
                  (a) create an Account for anyone other than yourself, unless such person's prior
                  permission has been obtained;
                </li>
                <li>
                  (b) use an Account that is the name of another person with the intent to
                  impersonate that person.
                </li>
              </ul>
              Chillar cannot and will not be liable for any loss or damage arising from your failure
              to comply with this provision.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">5. USERNAME AND PASSWORD</h2>
          <ul className="list style-disk mb-4">
            <li>
              Once registered, in order to log into the Account on the App, you will be required to
              provide the following: (a) the mobile number used at the time of signing-up and One
              Time Password (OTP); or (b) such other login credentials that are adopted by Chillar
              from time to time and duly intimated to you for logging into the Account on the App
              (“Login Credentials”).
            </li>
            <li>
              You shall be solely responsible for maintaining confidentiality of the Login
              Credentials, and to the extent allowed by law, you hereby accept responsibility for
              all activities on the Account authenticated through the Login Credentials, whether
              authorized or not. You agree to keep Login Credentials confidential and secure, and
              you shall not give or make Login Credentials available, directly or indirectly, to any
              unauthorized individual. You acknowledge and agree that if you allow, whether through
              action or inaction, a person to gain access to the Login Credentials, with or without
              permission, you are authorizing that person to use the App through the Account, and
              you shall be responsible for all actions that result from such access, even if you did
              not want the actions performed, and even if they are unauthorized or fraudulent.
              Chillar shall not be held liable in any manner whatsoever for lack of any such
              authorization whilst creating the Account.
            </li>
            <li>
              Chillar cannot and will not be liable for any loss or damage arising from or arising
              out of your use of the App, theft of the Login Credentials, use of the Login
              Credentials or release of the Login Credentials to a third party or your authorization
              to allow another person to access and use the App using the Account.
            </li>
            <li>
              In case of any misappropriation or unauthorized access of the Account, you agree to
              communicate the same to Chillar immediately, in a manner indicated by Chillar. You
              shall further ensure that you exit/ logout from the Account at the end of each
              session. Chillar shall not be liable for any loss or damage arising from your failure
              to comply with this provision. You may be held liable for any losses incurred by
              Chillar or any other user of or visitor to the App due to authorized or unauthorized
              use of the Account, as a result of your failure in keeping the Login Credentials
              confidential.
            </li>
            <li>
              You agree that the information provided by you to Chillar, at all times (including
              during registration), will be true, accurate, up-to-date, and complete. You further
              agree that if you provide any information that is untrue, inaccurate, not up-to-date
              or incomplete or becomes untrue, inaccurate or incomplete or if Chillar has reasonable
              grounds to suspect that such information is untrue, inaccurate, not up-to-date,
              incomplete or not in accordance with this EULA, Chillar shall have the right to
              indefinitely suspend or terminate or block access to the Account on the App and refuse
              to provide you access to the App. You are advised to use due caution when providing
              any information to Chillar accordingly.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">6. SERVICES</h2>
          <ul className="list style-disk mb-4">
            <li>
              All users will need to register and comply with the terms of this EULA in order to be
              able to use and access the services on the App, which include (a) keeping track of
              your expenses on a day to day basis and having your expenses analyzed; (b) enabling
              investments of the surplus amount from your spends into the Mutual Fund Scheme on the
              App; and (c) providing such other services as may be made available on the App from
              time to time (“Services”). Chillar is not and will not be responsible for any claim or
              for any damages suffered that are related, directly or indirectly, to or arising out
              of your use of any Service on or through the App.
            </li>
            <li>
              Your Journey on the App as a new user
              <ul className="list style-none mb-4">
                <li>
                  (a) In order to avail Services on the App, you will be required to choose one of
                  your bank accounts displayed on the App (being linked to your mobile number
                  provided at the time of registration) or you will have the option to add details
                  of any other bank account through which you may wish to avail the Services
                  provided by the App. You will also be entitled to add, edit or delete your bank
                  accounts, from time to time, for availing the Services from the App. It being
                  clarified that the deletion of your Account on the App shall not pause the auto
                  debit of the investment by E-Mandate (defined below) as provided in the succeeding
                  paragraph, and in an event you want to pause the auto debit, you shall be required
                  to place a request for the same through the App.
                </li>
                <li>
                  (b) Upon selecting one or more of your bank accounts (“Bank Account”) you will
                  receive a request for an approval of an e-mandate for the relevant Bank Account.
                  E-mandate is a payment service initiated by Reserve Bank of India and the National
                  Payments Corporation of India and provides the underlying infrastructure for
                  collecting recurring payments in India (“E-Mandate”). Upon providing your
                  approval, you understand that you will authorise the auto debit of surplus
                  payments to the Mutual Fund Scheme from your Bank Account in accordance with the
                  terms of this EULA. It being clarified that such auto debit feature is only
                  applicable if you are the account holder or one of the account holders (in case of
                  joint account) of the Bank Account. Through the service of the App, you will be
                  entitled to pre-set the quantum of surplus payments from your daily spends that
                  will be invested in the Mutual Fund Scheme. The parameters include without
                  limitation, (i) setting of multipliers so that a fixed amount as decided by the
                  user is auto-debited by the E-Mandate each time the user spends on a third-party
                  website through the Bank Account; and (ii) such other parameters as may be
                  provided by Chillar from time to time, provided the same is in accordance to the
                  parameters as required by E-Mandate. You hereby declare that (i) you are the
                  rightful and correct owner of the Bank Account and have the authority to make any
                  monetary transactions, without any limitation to the quantum of such transactions,
                  from the Bank Account; and (ii) the transactions consummated on the App will be
                  through your own Bank Account only, with such transactions being in compliance
                  with all applicable laws, rules and regulations, notifications or directives,
                  including without limitation, anti-money laundering laws and anti-corruption laws.
                </li>
                <li>
                  (c) You will also be required to provide the email address linked to the relevant
                  Bank Account. Upon choosing the medium prompted on the App through which you would
                  like to continue the sign-up process, the emails for the e-mail address selected
                  by you shall be accessible by Chillar for the purpose of provision of Services in
                  accordance to the terms of this EULA. By choosing the medium and providing such
                  information, you hereby authorize Chillar to (i) access your emails solely for the
                  purpose of reading the transactions undertaken by you in connection with such Bank
                  Account to analyze the spends made by yourself through the Bank Account; and (ii)
                  transfer information in relation to your spends from the Bank Account to any third
                  party/ vendor engaged by Chillar in furtherance to the Services.
                </li>
              </ul>
              <li>
                Your Journey on the App as an existing user <br />
                Once you are registered on the App and have provided all the information required by
                Chillar for the provision of Services, you, as a user, will be provided access to a
                dashboard which shall enable you to access and view:
                <ul className="list style-none mt-4 mb-4">
                  <li>
                    (a) your spend analysis in the form of an online ledger which reflects the
                    spends made by you through your various Bank Account; and
                  </li>
                  <li>
                    (b) in a single platform all the investments made by you in the Mutual Fund
                    Scheme through your Account on the App. Chillar shall also maintain a record of
                    your information with respect to the Services availed / intended to be availed
                    by you through the App.
                  </li>
                </ul>
              </li>
              <li>
                Spend analysis <br />
                <ul className="list style-none mt-4 mb-4">
                  <li>
                    (a) This Service is designed to help you keep track of your expenses incurred
                    within a particular period. Due care is taken to ensure that each user’s
                    transactions using this Service is kept discreet, with the requisite details
                    being visible only to the relevant users. You agree and understand that such
                    Service is provided by Chillar by accessing emails from the relevant bank for
                    your Bank Account. Chillar hereby represents and warrants that Chillar shall
                    only read emails pertaining to your spends from the relevant Bank Account and
                    shall not try to access to any of your other emails. In doing so, Chillar cannot
                    and will not be responsible, under any circumstance whatsoever, for any breach
                    under this EULA or any other agreement.
                  </li>

                  <li>
                    (b) You further agree and understand that the provision of this Service is
                    dependent on the information received from your registered Bank Account and that
                    and Chillar at no point of is responsible for the accuracy of such information
                    to you whilst using this Service. In an event of inaccuracy, delay or
                    incompleteness of such information, this Service may be accordingly affected.
                    Chillar shall not be liable for any deficiency in the provision of this Service
                    if such deficiency is a result of or in connection with inaccurate, delayed or
                    incomplete information provided by the relevant bank. Chillar cannot and will
                    not be responsible, under any circumstance whatsoever, for any inaccurate
                    information displayed on such online ledger.
                  </li>
                  <li>
                    (c) While Chillar uses commercially reasonable efforts to provide you with a
                    daily backup of all the transactional information in relation your spends,
                    Chillar is not responsible to save, backup, and archive such information.
                  </li>
                </ul>
                <li>
                  Investment related services
                  <ul className="list style-none mt-4 mb-4">
                    <li>
                      (a) This Service is designed to help you increase your savings by facilitating
                      the investment of the surplus amounts from your third-party spends into Mutual
                      Fund Scheme. You acknowledge and agree that (i) the App merely provides
                      assistance in relation to the investment services and any investment is made
                      directly by you into the Mutual Fund Scheme of IPRUAMC; and (ii) Chillar is
                      not acting as an advisor or consultant in facilitating such investments in the
                      Mutual Fund Scheme. You hereby declare that the amounts invested by you in the
                      Mutual Fund Scheme, as part of this Service, will be derived through
                      legitimate sources and will not held or designed for the purpose of
                      contravention of any applicable law.
                    </li>
                    <li>
                      (b) Chillar uses Application Programming Interfaces (APIs) and portals
                      provided by IPRUAMC to facilitate investment transactions in the Mutual Fund
                      Scheme. Chillar maintains the status of the investment transactions, portfolio
                      etc. based on the data provided by IPRUAMC and as per the regulations
                      prescribed from time to time. Chillar will receive commission from IPRUAMC on
                      the total amount of investment made by the users of the App in the Mutual Fund
                      Scheme in accordance with the terms agreed with IPRUAMC.
                    </li>
                    <li>
                      (c) You understand and agree that this Service only facilitates the sale and
                      distribution of the Mutual Fund Scheme through the App and all your monetary
                      transactions will happen directly from the relevant Bank Account to the Mutual
                      Fund Scheme, with Chillar having no access to the such transaction. Should you
                      choose to proceed with any investments in the Mutual Fund Scheme, Chillar will
                      not be a party to such interaction and will not be liable in any manner with
                      respect to the mutual fund units allotted to you by IPRUAMC.
                    </li>
                    <li>
                      (d) Chillar does not, and is not obliged to, offer all mutual fund schemes for
                      investment or as the case may be, all kind of investment advisory services.
                      Simply by making the Mutual Fund Scheme available for investments on the App,
                      Chillar does not make any representation as to the quality, bona fides or
                      nature of IPRUAMC or the Mutual Fund Scheme, or any other representation,
                      warranty or guaranty, express or implied in respect of the Mutual Fund Scheme.
                      You hereby agree and acknowledge that the data and information provided on the
                      App does not constitute advice of any nature whatsoever, and shall not be
                      relied upon by you while making investment decisions, for which you shall be
                      solely responsible. In no event shall Chillar be liable to you for any loss or
                      damage that may cause or arise from or in relation to these EULA and/or due to
                      use of this Service through the App.
                    </li>
                    <li>
                      (e) You agree to make an informed independent investment decision by reading
                      the offer documents of the Mutual Fund Scheme. Furthermore, before investing,
                      you shall be required to seek independent financial planning, legal,
                      accounting, tax or other professional advice, if required.
                    </li>
                    <li>
                      (f) You further agree that IPRUAMC reserves the right to accept/reject
                      applications or redeem the mutual fund units allotted to you as it may deem
                      fit. In no event shall Chillar be liable to you for any such decisions taken
                      by IPRUAMC. Chillar shall also not be required to resolve any disputes arising
                      between the users involved in any investments and shall have no responsibility
                      or liability, whether financial or otherwise, for any loss or damage you may
                      suffer in case you do not receive any profit on your investments. The
                      transaction is between you and IPRUAMC and incase of any dispute in relation
                      to the Mutual Fund Scheme, the responsibility for redressal of any of your
                      complaints will rest solely with IPRUAMC at all times.
                    </li>
                  </ul>
                  <li>
                    The provision of Services on the App may be interrupted, including for
                    maintenance, repairs, upgrades, or network or equipment failures. Chillar
                    strives to keep the App up and running; however, all online services suffer
                    occasional disruptions and outages, and Chillar isn’t liable for any disruption
                    or loss you may suffer as a result.
                  </li>
                  <li>
                    Chillar may discontinue some or all of the Services provided on the App, as it
                    may deem fit, including certain features and the support for certain devices and
                    platforms, at any time.
                  </li>
                </li>
              </li>
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">7. AGREEMENT TO RECEIVE COMMUNICATION</h2>
          <p className="text">
            You hereby by way of accepting this EULA consent to the receipt of communication from
            Chillar by way of in app messages, Short Message Service (SMS) messages, e-mails,
            promotional, marketing calls and newsletters. These emails could relate to your
            registration, transactions that you carry out through the App and promotions that are
            undertaken by Chillar, services from Chillar and its third-party partners, as
            applicable.
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">8. LINKS TO THIRD PARTY WEBSITES</h2>
          <p className="text">
            The App may contain links and interactive functionality interacting with the websites of
            third parties. Chillar is not responsible for and has no liability for the
            functionality, actions, inactions, privacy settings, privacy policies, terms, or content
            of any such websites. Before enabling any sharing functions to communicate with any such
            website or otherwise visiting any such website, Chillar strongly recommends that you
            review and understand the terms and conditions, privacy policies, settings, and
            information-sharing functions of each such third-party website.
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">9. USE OF THE APP</h2>
          <ul className="list style-disk mb-4">
            <li>
              You agree, undertake and covenant that, during the use of the App, you shall not host,
              display, upload, modify, publish, transmit, store, update or share any information
              that:
              <ul className="list style-none mt-4 mb-4">
                <li>
                  (a) belongs to another person and to which you do not have any right, except as
                  expressly provided in this EULA.
                </li>
                <li>
                  (b) is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic,
                  pedophilic, libelous, invasive of another person's privacy, insulting or harassing
                  on the basis of gender, hateful or racially or ethnically objectionable, relating
                  or encouraging money laundering or gambling, or otherwise inconsistent or contrary
                  to the applicable laws in force in any manner whatsoever.
                </li>
                <li>(c) is misleading in any way.</li>
                <li>(d) is harmful to minors.</li>
                <li>
                  (e) involves the transmission of "junk mail", "chain letters", or unsolicited mass
                  mailing or "spamming" .
                </li>
                <li>
                  (f) infringes upon or violates any third party's rights including, but not limited
                  to, any patent, trademark, copyright or other proprietary rights or intellectual
                  property rights, rights of privacy (including without limitation unauthorized
                  disclosure of a person's name, email address, physical address or phone number).
                </li>
                <li>
                  (g) provides instructional information about illegal activities such as violating
                  someone's privacy, or providing or creating computer viruses.
                </li>
                <li>
                  (h) tries to gain unauthorized access or exceeds the scope of authorized access to
                  the App or to profiles, communities, Account information, bulletins, or other
                  areas of the App or solicits passwords or personally identifying information for
                  commercial or unlawful purposes from other users of the App.
                </li>
                <li>
                  (i) engages in commercial activities without Chillar’s prior written consent such
                  as engages in contests, sweepstakes, barter, advertising etc.
                </li>
                <li> (j) interferes with another person’s use of the App.</li>
                <li> (k) impersonates another person.</li>

                <li>
                  (l) threatens the unity, integrity, defence, security or sovereignty of India,
                  friendly relations with foreign States, or public order, or causes incitement to
                  the commission of any cognizable offence or prevents investigation of any offence
                  or is insulting any foreign States.
                </li>
                <li>
                  (m) refers to any website or URL that, in Chillar’s sole discretion, contains
                  material that is inappropriate for the App or any other website, contains content
                  that would be prohibited or violates the letter or spirit of this EULA.
                </li>
                <li>
                  (n) deceives or misleads the addressee/ users about the origin of the messages or
                  knowingly and intentionally communicates any information which is patently false
                  or misleading or grossly offensive or menacing in nature but may reasonably be
                  perceived as a fact.
                </li>
                <li>
                  (o) is patently false and untrue, and is written or published in any form, with
                  the intent to mislead or harass a person, entity or agency for financial gain or
                  to cause any injury to any person.
                </li>
                <li>
                  (p) contains software viruses or any other computer codes, files or programs
                  designed to interrupt, destroy or limit the functionality of any computer
                  resource; or contains any trojan horses, worms or other computer programming
                  routines that may damage, detrimentally interfere with, diminish value of,
                  covertly intercept or steal any system, data or personal information.
                </li>
                <li> (q) violates any applicable law for the time being in force.</li>
              </ul>
            </li>
            <li>
              You hereby accept full responsibility for any consequences that may arise from your
              use of the App, and expressly agree and acknowledge than in no event and under no
              circumstances shall Chillar be held liable to you for any liabilities or damages
              resulting from or arising out of your use of the App. You shall not use anyone else’s
              Account at any time.
            </li>
            <li>
              You agree and acknowledge that you shall not use the App for any fraudulent,
              malicious, illegal or unauthorized purpose/activities. You agree to comply with all
              applicable laws pertaining to your use of the App. You further agree and acknowledge
              that you shall use the Services provided on the App only for your personal use and not
              for business purposes.
            </li>
            <li>
              Chillar shall have the right, but not the obligation, to monitor your access to or use
              of the App to ensure your compliance with this EULA or applicable laws, at its sole
              discretion.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">10. USERS’ POSTS AND REVIEWS</h2>
          <ul className="list style-disk mb-4">
            <li>
              The App may allow you to post certain content, data or information belonging to you,
              such as reviewing and allowing you to share your experience and views about a
              particular product/ service, and rate product/ service, post your comments and reviews
              in relation to the product/ service on the App on specific pages of the App, as well
              as submit/ post any suggestions, comments, questions or other information to Chillar
              using the App (collectively referred to “User Content”).
            </li>
            <li>
              You, being the originator of the User Content, are responsible for the User Content
              that you upload, post, publish, transmit or otherwise make available on the App. You
              represent and covenant that you have obtained all relevant consents and approvals in
              order to post any User Content and shall not post any content relating to or owned by
              a third party for the promotion or sale of products/ services of such third party. You
              further represent that all such User Content will be in accordance with applicable
              laws. You acknowledge that Chillar does not endorse any User Content on the App and is
              not responsible or liable for any User Content. Chillar reserves the right to remove
              any third-party marketing material and disable access to the User Content on the App.
            </li>

            <li>
              You hereby grant Chillar a perpetual, non-revocable, worldwide, royalty-free and
              sub-licensable right and license to use, copy, distribute, display, publish, transmit,
              make available, reproduce, modify, adapt the User Content and create derivative works
              of the User Content. You represent and warrant that you own or otherwise control all
              of the rights to the User Content that you post or that you otherwise provide on or
              through the App; and that, as at the date that the User Content is posted or submitted
              on the App: (i) the User Content is accurate; (ii) use of the User Content you supply
              does not breach this EULA; and (iii) that such User Content is lawful.
            </li>
            <li>
              You further represent and warrant that while posting any User Content on the App you
              shall not use any offensive, libellous, derogatory, hateful or racially or ethnically
              objectionable language. Further, you shall not post any content on the App that is
              obscene, pornographic, constitutes an “indecent representation of women” as provided
              in The Indecent Representation of Women (Prohibition) Act, 1986.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">11. CONTENT AND INTELLECTUAL PROPERTY RIGHTS</h2>
          <ul className="list style-disk mb-4">
            <li>
              Except for User Content, all of the content and services and products provided on the
              App, including text, software, scripts, code, designs, graphics, photos and other
              content and the copyrights, trademarks, service marks, logos, trade names, and other
              intellectual and proprietary rights associated therewith (“IP”) is owned by Chillar or
              others (including without limitation, the third party service providers) that Chillar
              licenses such content from, and is protected by copyright, trademark, patent and other
              intellectual property laws.
            </li>
            <li>
              You hereby acknowledge that the IP constitutes original works and has been developed,
              compiled, prepared, revised, selected, and arranged by Chillar and others through the
              application of methods and standards of judgment developed and applied through the
              expenditure of substantial time, effort, and money and constitutes valuable
              intellectual property of Chillar and such others. You thereby agree to protect the
              proprietary rights of Chillar during and after the term of this EULA. You may not
              selectively download portions of the App without retaining the copyright notices. You
              may download material from the App only for your own personal use and for no
              commercial purposes whatsoever.
            </li>
            <li>
              You shall use the App strictly in accordance with this EULA, and shall not, directly
              or indirectly, (i) decompile, disassemble, reverse engineer, or attempt to derive the
              source code of, or in any manner decrypt, the App; (ii) make any modification,
              adaptation or improvement, enhancement, translation or derivative work from the App;
              (iii) violate any applicable laws, rules or regulations in connection with your access
              or use of the App, including your use of any Content; (iv) remove or obscure any
              proprietary notice (including any notices of copyright or trademark) forming a part of
              the App; (v) use the App for any commercial or revenue generation endeavours, or other
              purposes for which it is not designed or intended; (vi) distribute or transmit the App
              or other services; (vii) use the App for data mining, scraping, crawling, redirecting,
              or for any purpose not in accordance with this EULA; (ix) use the App for creating a
              service or software that is directly or indirectly, competitive with the App or any
              services offered by Chillar; or (x) derive any confidential information, processes,
              data or algorithms from the App.
            </li>
            <li>
              Any infringement shall lead to appropriate legal proceedings against you at an
              appropriate forum for seeking all available remedies under applicable laws of the
              country.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">
            12. TERMINATION OF ACCESS TO APP OR DEACTIVATION OF SERVICE
          </h2>
          <ul className="list style-disk mb-4">
            <li>
              Your Account can be terminated/deactivated at any time by:
              <ul className="list style-none mt-4 mb-4">
                <li>
                  (a) You, by requesting for such termination/deactivation of your Account on the
                  App.
                </li>
                <li>
                  (b) Chillar, in its sole discretion for any reason or no reason, including your
                  violation of this EULA or lack of use of App. You acknowledge that the termination
                  of access to the App may be affected without any prior notice, and Chillar may
                  immediately deactivate or delete the Account and all related information and/or
                  bar any further access to the Account or the services provided on the App, subject
                  to applicable law.
                </li>
              </ul>
            </li>
            <li>
              Prior to deactivation or deletion of your Account for any reason, Chillar shall
              require you to redeem all the amount invested in the Mutual Fund Scheme. Such redeemed
              amount will be transferred in accordance with the terms of the Mutual Fund Scheme.
            </li>
            <li>
              The deletion/deactivation of your Account shall automatically result in (i) stopping
              the auto-debit function for your registered Bank Account(s); and (ii) preventing any
              further access to your emails by Chillar. Upon such deletion/deactivation, your
              Account information shall be retained for the periods specified in the Policy.
            </li>
            <li>
              Alternatively, you may stop/pause the auto debit function for a particular Bank
              Account or from all your Bank Accounts, however the same shall not automatically
              result in preventing the access to your emails which shall be continued for the
              purpose of provision of Services in relation to spend analysis Service.
            </li>
            <li>
              The disclaimer of warranties, the limitation of liability, and the governing law
              provisions shall survive any termination of this EULA.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">13. REFERRALS AND REWARDS</h2>
          <p className="text">
            From time to time, Chillar may offer referral bonuses and rewards to you for inviting
            new users to the App and for completing other activities.
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">14. RIGHTS & RESERVATIONS</h2>
          <ul className="list style-disk mb-4">
            <li>
              Chillar reserves the right to:
              <ul className="list style-none mt-4 mb-4">
                <li>
                  (a) Make all decisions in relation to inter alia your registration with Chillar;
                </li>
                <li>
                  (b) Request additional documents and additional information from you for the
                  purpose of your registration as a user with the App; and
                </li>
                <li>
                  (c) Deny access to the App or any portion thereof without notice for the following
                  reasons (i) any unauthorized access or use by you; (ii) attempt(s) to assign or
                  transfer any rights granted to you under this EULA; (iii) violation of any of the
                  provisions of the EULA or any applicable laws.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">15. DISCLAIMER OF WARRANTIES</h2>
          <p className="text">
            THE APP AND ALL INFORMATION, CONTENT, MATERIALS AND SERVICES INCLUDED ON OR OTHERWISE
            MADE AVAILABLE TO YOU THROUGH THE APP (COLLECTIVELY, THE "CONTENT") ARE PROVIDED BY
            CHILLAR ON AN "AS IS," "AS AVAILABLE" BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF
            ANY KIND. CHILLAR MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
            IMPLIED, AS TO THE OPERATION OF THE APP, THE ACCURACY OR COMPLETENESS OF THE CONTENT OR
            THE SERVICES AND THE ACCURACY OF THE INFORMATION IN RELATION TO YOUR BANK ACCOUNT OR
            OTHERWISE. WITHOUT LIMITING THE FOREGOING, CHILLAR MAKES NO WARRANTY THAT THE APP OR
            SERVICES WILL MEET YOUR REQUIREMENTS OR YOUR USE OF THE APP WILL BE UNINTERRUPTED,
            TIMELY, SECURE OR ERROR-FREE OR THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE APP
            WILL BE EFFECTIVE, ACCURATE OR RELIABLE. YOU ACKNOWLEDGE AND UNDERSTAND THAT THIRD PARTY
            SERVICES ARE BEING MADE AVAILABLE ON THE APP IN RELATION TO WHICH CHILLAR MAY HAVE
            PARTNERSHIPS WITH SOME OF THESE THIRD PARTIES IN ORDER TO FACILITATE THE PROVISION OF
            SERVICES TO YOU THROUGH THE APP. HOWEVER, YOU ACKNOWLEDGE AND AGREE THAT CHILLAR, AT NO
            TIME, IS MAKING ANY REPRESENTATIONS OR WARRANTIES REGARDING PROVISION OF SERVICES BY ANY
            OF THESE THIRD PARTIES NOR WILL CHILLAR BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
            CONSEQUENCES OR CLAIMS ARISING OUT OF OR IN CONNECTION WITH SUCH THIRD PARTIES. CHILLAR
            SHALL HAVE NO RESPONSIBILITY FOR ANY DAMAGE TO YOUR MOBILE PHONE OR LOSS OF DATA THAT
            RESULTS FROM THE DOWNLOAD OF ANY CONTENT, MATERIALS, DOCUMENTS OR INFORMATION. YOU
            EXPRESSLY AGREE THAT THE USE OF APP IS AT YOUR OWN DISCRETION AND SOLE RISK AND THAT YOU
            WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE RESULTING FROM ANY TRANSACTION. NO ADVICE OR
            INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM CHILLAR OR THROUGH OR FROM
            THE APP OR CONTENT SHALL CREATE ANY WARRANTY. YOU WILL NOT SOLELY RELY ON THE VIEWS,
            REPRESENTATIONS (WHETHER WRITTEN OR ORAL OR OTHERWISE), RECOMMENDATIONS, OPINIONS,
            REPORTS, ANALYSIS, INFORMATION OR OTHER STATEMENT MADE BY CHILLAR OR ANY OF ITS AGENTS,
            DIRECTORS, OFFICERS OR EMPLOYEES. IN THE EVENT THAT YOU SEEK TO MAKE AN INVESTMENT
            PURSUANT TO THE INFORMATION OR RECOMMENDATION PROVIDED BY CHILLAR, YOU SHALL DO SO AT
            YOUR OWN RISK, AND SHOULD CONSIDER YOUR FINANCIAL SITUATION, OBJECTIVES AND NEEDS AND
            CONSULT WITH YOUR LEGAL, BUSINESS, TAX AND/OR OTHER PROFESSIONAL ADVISORS TO DETERMINE
            THE APPROPRIATENESS AND CONSEQUENCES OF SUCH AN INVESTMENT. YOU AGREE THAT ALL THE FINAL
            DECISIONS WILL BE YOURS AND CHILLAR WILL NOT HAVE ANY LIABILITY FOR CONSEQUENCES OF SUCH
            DECISIONS. CHILLAR WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE
            OF APP OR THE CONTENT INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, CONSEQUENTIAL,
            PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING. TO THE FULL
            EXTENT PERMITTED BY LAW, CHILLAR DISCLAIMS ANY AND ALL REPRESENTATIONS AND WARRANTIES
            WITH RESPECT TO THE APP AND ITS CONTENT, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
            LIMITATION, WARRANTIES OF TITLE AND NON- INFRINGEMENT, MERCHANTABILITY, SATISFACTORY
            QUALITY, ACCURACY, COMPATIBILITY, APPLICABILITY, USABILITY, APPROPRIATENESS, FITNESS FOR
            A PARTICULAR PURPOSE OR USE AND ANY WARRANTY THAT MAY ARISE OUT OF COURSE OF
            PERFORMANCE, COURSE OF DEALING, OR USAGE OF TRADE. YOU HEREBY AGREE TO WAIVE, RELEASE,
            DISCHARGE, AND HOLD HARMLESS CHILLAR, ITS AFFILIATES AND GROUP COMPANIES (AS APPLICABLE)
            AND EACH OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS (AS APPLICABLE),
            FROM ANY AND ALL CLAIMS, LOSSES, DAMAGES, LIABILITIES, EXPENSES AND CAUSES OF ACTION
            ARISING OUT OF THE SERVICES AVAILABLE ON THE APP AND FROM ANY CLAIMS YOU MAY HAVE
            AGAINST CHILLAR WITH RESPECT TO THE SERVICES PROVIDED BY ANY THIRD PARTY
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">16. LIMITATION OF LIABILITY</h2>
          <p className="text">
            In no event shall Chillar, its affiliates and group companies (as applicable) and each
            of their respective officers, directors, employees and agents (as applicable) be liable
            for any special, incidental, indirect, consequential, exemplary or punitive damages, or
            loss of profit or revenues whatsoever resulting from any (a) errors, mistakes, or
            inaccuracies of content; (b) any unauthorized access to or use of the secure servers
            and/or any and all personal and/or other user information stored therein; (c) any
            interruption or cessation of transmission to or from the services on the App or the
            failure of the Services to remain operational for any period of time; (d) any bugs,
            viruses, trojan horses, or the like, which may be transmitted to or through the App by
            any third party; (e) any loss of your data arising from any use of or inability to use
            any Content or other parts of the App; (f) your failure to keep Login Credentials or
            Account details secure and confidential; (g) non-availability of the Services in certain
            geographical areas; and/or (h) any errors or omissions in any content or for any loss or
            damage of any kind incurred as a result of the use of any content posted, emailed,
            transmitted, or otherwise made available via the App, whether based on warranty,
            contract, tort, or any other legal theory, and whether or not Chillar is advised of the
            possibility of such damages. Chillar shall not be liable under any circumstance for
            damages arising out of or in any way related to Services and/or information provided by
            third parties through the App.
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">17. INDEMNIFICATION</h2>
          <p className="text">
            You shall indemnify and hold harmless Chillar, its affiliates, group companies (as
            applicable) and their respective officers, directors, agents, and employees, from any
            and all claims or demands, or actions including reasonable attorneys' fees, made by any
            third party or penalty imposed due to or arising out of your breach of this EULA,
            Privacy Policy and other policies, or your violation of any law, rules or regulations or
            the rights (including infringement of any intellectual property rights) of a third
            party.
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">18. PRIVACY POLICY</h2>
          <p className="text">
            Please review the Privacy Policy, which also governs your visit to the App, to
            understand Chillar’s privacy practices. The personal information / data provided to
            Chillar by you during the course of usage of the App will be treated as strictly
            confidential and in accordance with the Privacy Policy and applicable laws and
            regulations. If you object to your information being transferred or used, please do not
            use the App.
          </p>
        </div>

        <div className="box">
          <h2 className="title mb-4">19. ASSIGNMENT</h2>
          <p className="text">
            You shall not license, sell, transfer or assign your rights, obligations, or covenants
            under this EULA in any manner without Chillar’s prior written consent. Chillar reserves
            the right, at its own discretion, to freely assign and transfer the rights and
            obligations under this EULA to any third party.
          </p>
        </div>

        <div className="box">
          <h2 className="title mb-4">20. GOVERNING LAW AND DISPUTE RESOLUTION</h2>
          <p className="text">
            This EULA shall be governed by and interpreted and construed in accordance with the laws
            of India. The place of jurisdiction shall exclusively be in Mumbai. In the event of any
            dispute arising out of this EULA the same shall be settled by a binding arbitration
            conducted by a sole arbitrator, appointed jointly by both parties and governed by the
            Arbitration and Conciliation Act, 1996. The venue and seat of arbitration shall be
            Mumbai.
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">21. SEVERABILITY AND WAIVER </h2>
          <p className="text">
            This EULA, the Privacy Policy and other referenced material herein or on the App, are
            the entire agreement between you and Chillar with respect to the products/services
            offered on the App, and supersede all prior or contemporaneous communications and
            proposals (whether oral, written or electronic) between you and Chillar with respect
            thereto and govern the future relationship. If any provision of the EULA is found to be
            unenforceable or invalid, that provision will be limited or eliminated to the minimum
            extent necessary so that the EULA will otherwise remain in full force and effect and
            enforceable. The failure of either party to exercise in any respect any right provided
            for herein shall not be deemed a waiver of any further rights hereunder
          </p>
        </div>

        <div className="box">
          <h2 className="title mb-4"> 22. GRIEVANCE REDRESSAL MECHANISM</h2>
          <ul className="list style-disk mb-4">
            <li>
              In accordance with the Information Technology Act, 2000 and Rules made thereunder, the
              name and contact details of the Grievance Officer are provided below: <br />
              Name: Mr. Rajkumar Desai <br />
              Email: <label>rajkumar@surplusapp.in</label>
              <br />
              Phone No: 8879476576
            </li>
            <li>
              For registering your complaint, please write to the Grievance Officer at the
              above-mentioned email address in relation to any violation of this EULA or the
              Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules,
              2021. The Grievance Officer shall redress the complaint in accordance with the
              provisions of the Information Technology Act, 2000 and Rules made thereunder.
            </li>
          </ul>
        </div>
        <div className="box">
          <h2 className="title mb-4">23. REFUND AND CHARGEBACK</h2>
          <p className="text">
            Chillar Fintech Pvt Ltd shall not be responsible for any incorrect details or inputs
            given from User’s end at the time of transaction at Surplus App. The Estimated time to
            credit differs on a Bank to Bank basis, which may take 48 to 72 working hours in a
            normal scenario. In some situations the transaction settlement may take much longer
            time. Surplus will not be responsible for such scenarios and the User may contact the
            Bank considering the timeline. Surplus, in any scenario, will not be responsible for any
            late fee/ charges imposed by any merchant due to failure in processing the payment. In
            case the money is debited from User’s Bank account or Instrument and not credited within
            72 (seventy-two) working hours of completion of the transaction then User may contact
            the Customer support section. The incident will be lodged and investigated, if it is
            found that money was indeed charged to User payment instrument without delivery of the
            payment, subject to the correct information provided by the user, then the money will be
            refunded to the Source Account within twenty-five (25) working days or as per the
            present law mandate, from the date of receipt of User query. All refunds will be
            credited to the Source Account. However, Surplus disclaims any and all liability,
            including for refunds, in the event of any error arising from a failure in the
            applicable bank, card network, BNPL Partner, BBPS network or any other intermediary
            service provider’s systems or networks. In case a payment has been erroneously made and
            credited towards a Bill, Service or Bank account linked to the User, Surplus reserves
            the right to automatically initiate a refund through User’s bank. The User is hereby
            giving her/his consent to refund the wrongful initiated payment and to offset any
            wrongful transaction. Such transaction or action will be informed to the User in a
            timely manner.
          </p>
        </div>
        <div className="box">
          <h2 className="title mb-4">24. HOW TO CONTACT US</h2>
          <p className="text">
            If you have questions or concerns about this EULA, please contact Chillar at the
            following email address: <label>rajkumar@surplusapp.in</label>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default LicenceAgreement;
