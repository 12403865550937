import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import f1 from '../../images/icons/1outline.png'
import f2 from '../../images/icons/2filled.png'
import f3 from '../../images/icons/3filled.png'
import o1 from '../../images/icons/1filled.png'
import o2 from '../../images/icons/2outline.png'
import o3 from '../../images/icons/3outline.png'
import pImage1 from '../../images/partner-left.png'
import pImage2 from '../../images/partner-middle.png'
import pImage3 from '../../images/partner-right.png'
import './styles.scss'
const Partner=()=>{
    const [index, setIndex] = useState(0);
    return (
        <div className='partner'>
            <h1 className='partner-head'>For <span>Partner Brands</span></h1>

            <div className='partner-tab-box'>
                <div onClick={() => setIndex(0)} id='tab1' className={`partner-tab ${index === 0 ? 'selected-tab' : ''}`}>
                    <img src={index === 0 ? o1 : f1} alt="partner-icon" className='partner-icon' />
                    <p>Acquire and Retain users</p>
                </div>
                <div onClick={() => setIndex(1)} id='tab2' className={`partner-tab ${index === 1 ? 'selected-tab' : ''}`}>
                    <img src={index === 1 ? o3 : f3} alt="partner-icon" className='partner-icon' />
                    <p>Pay per performance</p>
                </div>
                <div onClick={() => setIndex(2)} id='tab3' className={`partner-tab ${index === 2 ? 'selected-tab' : ''}`}>
                    <img src={index === 2 ? o2 : f2} alt="partner-icon" className='partner-icon' />
                    <p>Target niche users</p>
                </div>
            </div>

            <Carousel activeIndex={index} onSelect={setIndex} wrap={true} prevIcon={null} nextIcon={null} >

                <Carousel.Item interval={null} >
                    <div className='partner-item'>
                        <div className='partner-image-box'>
                            <img src={pImage1} className='partner-image' alt='' />
                        </div>
                        <div className='partner-text'>
                            <h2 className='partner-head'>Optimise your Cost of Acquisition</h2>
                            <p className='partner-para'>Brands, are you worried that your user acquisition and retention costs are going through the roof? Here is why you should add Surplus to list of partners for distribution of your products:</p>

                            <ul className='partner-list'>
                                <li className='partner-list-item'>Acquire new users based on their spends.</li>
                                <li className='partner-list-item'>Retain and reactivate your existing users with special offers</li>
                                <li className='partner-list-item'>Your brand could be a solution to our users high spend problem</li>
                            </ul>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={2000} >
                    <div className='partner-item'>
                        <div className='partner-image-box'>
                            <img src={pImage2} className='partner-image' alt='' />
                        </div>
                        <div className='partner-text'>
                            <h2 className='partner-head'>Why pay upfront and hope for the best?</h2>
                            <p className='partner-para'>With Surplus, you only pay for the users you acquire, after you acquire them.</p>

                            <ul className='partner-list'>
                                <li className='partner-list-item'>Unlike other acquisition channels, we only charge you for the users you acquire through us</li>
                                <li className='partner-list-item'>Optimise your acquisition costs</li>
                                <li className='partner-list-item'>Always best to acquire users from multiple sources to ensure your brand gets the right mix of quality and cost for optimum growth</li>
                            </ul>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <div className='partner-item'>
                        <div className='partner-image-box'>
                            <img src={pImage3} className='partner-image' alt='' />
                        </div>
                        <div className='partner-text'>
                            <h2 className='partner-head'>Overspending on user discounts?</h2>
                            <p className='partner-para'>Don’t just offer discounts to anyone and everyone. The right users </p>

                            <ul className='partner-list'>
                                <li className='partner-list-item'>Target users based on their spend pattern and stay ahead of competition</li>
                                <li className='partner-list-item'>Offer your most exclusive discounts only for the right users</li>
                                <li className='partner-list-item'>Surplus makes digital product sampling easier than ever before</li>
                            </ul>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Partner