export const overviewList = [
  'Automated & accurate expense tracking with detailed insights about your spends',
  'Buy and Manage subscriptions to stay on top of recurring bills',
  'Curb overspending by creating custom budgets across categories',
  'Tailored rewards so you can maximise your savings and minimise on spends',
];

export const returnPosition = (index, number) => {
  var arr = [];
  if (index === 0) {
    arr = ['left1', 'left2', 'center', 'right2', 'right1'];
    return arr[number - 1];
  } else if (index === 1) {
    arr = ['right1', 'left1', 'left2', 'center', 'right2'];
    return arr[number - 1];
  } else if (index === 2) {
    arr = ['right2', 'right1', 'left1', 'left2', 'center'];
    return arr[number - 1];
  } else if (index === 3) {
    arr = ['center', 'right2', 'right1', 'left1', 'left2'];
    return arr[number - 1];
  } else if (index === 4) {
    arr = ['left2', 'center', 'right2', 'right1', 'left1'];
    return arr[number - 1];
  }
};
