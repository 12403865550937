import React from 'react';
import './styles.scss';
import Slider from './slider.js';
import { overviewList } from './constants';
const Overview = () => {
  return (
    <div className="overview py-4">
      <div className="overview-text">
        <h3 className="overview-head">
          Make smarter transactions with <span>Surplus</span>
        </h3>
        <ul className="overview-list">
          {overviewList.map((e, index) => {
            return (
              <li key={index} className="overview-list-item">
                {e}
              </li>
            );
          })}
        </ul>
        <div className="overview-carousel">
          <Slider />
        </div>
      </div>
    </div>
  );
};

export default Overview;
