import React from 'react';
import logo from '../../images/logo.svg';
import './style.scss';
import gPlay from '../../images/google_play_button.svg';
import appStore from '../../images/app_store_button.svg';
import { Link } from 'react-router-dom';
import { APP_URL, PLAY_URL } from '../../utils/global.strings';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-top">
        <div className="footer-text">
          <a href="/">
            <img src={logo} alt={'SurplusApp'} />
          </a>
          <div className="footer-para">Chillar Fintech Private Limited</div>
          <button
            className="footer-button"
            onClick={() => {
              window.location.href = '/contact';
            }}
          >
            Contact us
          </button>
        </div>
        <div className="footer-links">
          <div>
            <div className=" mb-3">Company</div>
            <div className="footer-item mb-1">
              <a href={'#faq'}>FAQ's</a>
            </div>
            <div className="footer-item mb-1">
              <Link to={'/privacy-policy'}>Privacy Policy</Link>
            </div>
            <div className="footer-item mb-1">
              <Link to={'/licence-agreement'}>Terms of use</Link>
            </div>
          </div>
          <div>
            <div className=" mb-3">Useful Links</div>
            <div className="footer-item mb-1">
              <Link to={'/partner'}>Partner with us</Link>
            </div>
            <div className="footer-item mb-1">
              <Link to={'/contact'}>Contact us</Link>
            </div>
            <div className="footer-item mb-1">
              <a href={'https://discord.gg/hUvQZpTNPu'}>Discord Community</a>
            </div>
          </div>
          <div>
            <div className=" mb-3">Follow us</div>
            <div className="footer-item mb-1">
              <a href={'https://www.facebook.com/appsurplus'}>
                <i className="fab fa-facebook"></i> Facebook
              </a>
            </div>
            <div className="footer-item mb-1">
              <a href={'https://twitter.com/appsurplus'}>
                <i className="fab fa-twitter"></i> Twitter
              </a>
            </div>
            <div className="footer-item mb-1">
              <a href={'https://www.instagram.com/appsurplus/'}>
                <i className="fab fa-instagram"></i> Instagram
              </a>
            </div>
            <div className="footer-item mb-1">
              <a href={'https://www.linkedin.com/company/appsurplus/'}>
                <i className="fab fa-linkedin"></i> Linkedin
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Copyright © 2023 Surplus All Rights Reserved.</p>

        <div className="footer-bottom-right">
          <a href={PLAY_URL}>
            <img alt="footer-icon" src={gPlay} />
          </a>
          <a href={APP_URL}>
            <img alt="footer-icon" src={appStore} />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
