import React, { useState } from 'react';
import { aboutMapper } from './constants';
import aboutIcon from '../../images/about-icon.png';
import phoneCase from '../../images/case.png';
import aboutLineIcon from '../../images/about-icon2.png';
import './styles.scss';
const About = () => {
  const [selected, setSelected] = useState(1);

  return (
    <div className="about-section">
      <h1 className="about-head">
        Get <span>surplus</span> and start saving
      </h1>
      <div className="about container">
        <div className="about-content">
          <div className="about-cbox" onClick={() => setSelected(1)}>
            <h3 className="about-chead">
              Link Bank Accounts and track your expenses and investments
            </h3>
            <p className="about-cpara">
              Tired of manual or inaccurate SMS based expense tracking? Surplus lets you connect
              your bank accounts digitally to provide a seamless experience
            </p>
            <img
              src={selected === 1 ? aboutLineIcon : aboutIcon}
              width={selected === 1 ? '90%' : 15}
              className={`${selected === 1 ? 'line-selected' : ''}
            `}
              alt="radio-line-icon"
            />
          </div>
          <div className="about-cbox" onClick={() => setSelected(2)}>
            <h3 className="about-chead">Personalised insights based on your spends</h3>
            <p className="about-cpara">
              Truly tailored insights to help you understand your Personal Finance and make smarter
              decisions
            </p>
            <img
              src={selected === 2 ? aboutLineIcon : aboutIcon}
              width={selected === 2 ? '90%' : 15}
              className={`${selected === 2 ? 'line-selected' : ''}
            `}
              alt="radio-icon"
            />
          </div>
          <div className="about-cbox" onClick={() => setSelected(3)}>
            <h3 className="about-chead">Buy and manage subscriptions</h3>
            <p className="about-cpara">
              You can buy your favourite subscriptions from Surplus and toggle them OFF when not in
              use to optimise your spends
            </p>
            <img
              src={selected === 3 ? aboutLineIcon : aboutIcon}
              width={selected === 3 ? '90%' : 15}
              className={`${selected === 3 ? 'line-selected' : ''}
            `}
              alt="radio-icon"
            />
          </div>
          <div className="about-cbox" onClick={() => setSelected(4)}>
            <h3 className="about-chead">Live budget tracker to curb your expenses</h3>
            <p className="about-cpara">
              Monthly budgets don’t go as per the plan? Set budgets across categories and stay on
              course to optimise your budgets
            </p>
            <img
              src={selected === 4 ? aboutLineIcon : aboutIcon}
              width={selected === 4 ? '90%' : 15}
              className={`${selected === 4 ? 'line-selected' : ''}
            `}
              alt="radio-icon"
            />
          </div>
        </div>
        <div className="about-image-box">
          <img src={phoneCase} alt="Phone Case" className="phone-case-img" />
          <img className="about-image" src={aboutMapper[selected]} alt="surplus-app-mock" />
        </div>
      </div>
    </div>
  );
};

export default About;
