import React from 'react';
import Home from '../../components/home';
import Footer from '../../components/footer';
import Security from '../../components/security';
import About from '../../components/about';
import Overview from '../../components/overview';
import Partner from '../../components/partner';
import FAQ from '../../components/FAQ';
import Header from '../../components/header';

const HomeScreen = () => {
  return (
    <>
      <div style={{ background: '#f6f6f6' }}>
        <div className="home-section container px-lg-0 my-0">
          <Header />
          <Home />
        </div>
        <About />
        <div className="security-section container p-3 px-lg-0 py-3 py-lg-5">
          <Security />
        </div>
        <Overview />
        <div className="container p-3 px-lg-0 py-3 py-lg-5">
          <Partner />
        </div>
      </div>
      <FAQ />
      <Footer />
      <div className="qr-code">
        <img src="/qrcode.png" alt="surplus-qr-code" />
      </div>
    </>
  );
};

export default HomeScreen;
