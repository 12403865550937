import axios from "axios";


export const postAPI=(path, data)=>{
    return new Promise((resolve, reject)=>{
        axios.post(`${process.env.REACT_APP_API_URL}${path}`, data).then(response=>{
            if(response.data.status=='success'){
                resolve(response.data)
            }else{
                reject()
                alert("Try Again")
            }
        }).catch(err=>{
            reject(err)
        })
    })
}
