import React from 'react';
import logo from '../../images/logo.svg';
import { Link } from 'react-router-dom';
import './header.scss'
import { Color } from '../../utils/colors';
const Header = ({ isPartner }) => {
  return (
    <div className="header">
      <a href="/">
        <img src={logo} alt={'SurplusApp'} width={130} />
      </a>
      <div className="">
        <Link
          to={isPartner ? '/' : '/partner'}
          rel="noreferrer"
          style={{
            textDecoration: 'none',
            color: Color.primary,
            fontWeight:'bold'
          }}
        >
          {isPartner ? 'Home' : 'Partner with us'}
        </Link>
      </div>
    </div>
  );
};


export default Header