import React, { useState } from 'react';
import im1 from '../../images/slider/linkAccountScreen.JPG';
import im2 from '../../images/slider/expensePie.png';
import im3 from '../../images/slider/billsAndRecharge.png';
import im4 from '../../images/slider/dashboard.png';
import im5 from '../../images/slider/brandOffers.png';
import phoneCase from '../../images/case.png';

import { returnPosition } from './constants';
import './styles.scss';
import { useSwipeable } from 'react-swipeable';

const Slider = () => {
  const [active, setActive] = useState(2);

  const handlers = useSwipeable({
    onSwipedRight: () => setActive(active === 0 ? 4 : (active - 1) % 5),
    onSwipedLeft: () => setActive((active + 1) % 5),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });
  return (
    <div {...handlers} className="slider">
      <div className="slider-left">
        <div
          className="slider-left-box"
          onClick={() => {
            setActive(active === 0 ? 4 : (active - 1) % 5);
          }}
        >
          <i className="fa fa-angle-left slider-left-icon"></i>
        </div>
      </div>
      <div className="slider-content">
        <div className="slider-images">
          <img
            src={im1}
            alt="slider-image1"
            className={`slider-image ${returnPosition(active, 1)}`}
          />
          <img
            src={phoneCase}
            alt="Phone Case"
            className={`slider-image ${returnPosition(active, 1)} phone-case`}
          />
          <img
            src={im2}
            alt="slider-image2"
            className={`slider-image ${returnPosition(active, 2)}`}
          />
          <img
            src={phoneCase}
            alt="Phone Case"
            className={`slider-image ${returnPosition(active, 2)} phone-case`}
          />
          <img
            src={im3}
            alt="slider-image3"
            className={`slider-image ${returnPosition(active, 3)}`}
          />
          <img
            src={phoneCase}
            alt="Phone Case"
            className={`slider-image ${returnPosition(active, 3)} phone-case`}
          />
          <img
            src={im4}
            alt="slider-image4"
            className={`slider-image ${returnPosition(active, 4)}`}
          />
          <img
            src={phoneCase}
            alt="Phone Case"
            className={`slider-image ${returnPosition(active, 4)} phone-case`}
          />
          <img
            src={im5}
            alt="slider-image5"
            className={`slider-image ${returnPosition(active, 5)}`}
          />
          <img
            src={phoneCase}
            alt="Phone Case"
            className={`slider-image ${returnPosition(active, 5)} phone-case`}
          />
        </div>
        <div className="slider-indi-box">
          <div
            onClick={() => setActive(0)}
            className={`slider-indicator ${active === 0 ? 'selected' : ''}`}
          ></div>
          <div
            onClick={() => setActive(1)}
            className={`slider-indicator ${active === 1 ? 'selected' : ''}`}
          ></div>
          <div
            onClick={() => setActive(2)}
            className={`slider-indicator ${active === 2 ? 'selected' : ''}`}
          ></div>
          <div
            onClick={() => setActive(3)}
            className={`slider-indicator ${active === 3 ? 'selected' : ''}`}
          ></div>
          <div
            onClick={() => setActive(4)}
            className={`slider-indicator ${active === 4 ? 'selected' : ''}`}
          ></div>
        </div>
      </div>
      <div className="slider-right">
        <div className="slider-right-box" onClick={() => setActive((active + 1) % 5)}>
          <i className="fa fa-angle-right slider-right-icon"></i>
        </div>
      </div>
    </div>
  );
};

export default Slider;
