import React, { useState } from 'react'
import faqImage from '../../images/faq.png'
import './styles.scss'
import { faqContent } from './constants'
const FAQ = () => {
    const [selected, setSelected] = useState(1)
    
    return (
        <div id="faq" className='faq'>
            <div className='faq-left'>
                <h1>FAQs</h1>
                <p>We have curated some popular questions for you,<br /> contact us if you still have questions.</p>
                <img alt='faq-icon' src={faqImage} />
            </div>
            <div className='faq-right'>
                {
                    faqContent.map(e => {
                        return <div key={e.id} onClick={() => {
                            if (selected === e.id) {
                                setSelected(0)
                            } else {
                                setSelected(e.id)
                            }
                        }} className={`faq-card ${selected === e.id ? 'faq-card-selected' : ''}`}>
                            <div className='faq-card-toggle'>
                                <p>{e.q}</p>
                                <i className={`fa fa-angle-${selected === e.id ? 'up' : 'down'}`}></i>
                            </div>
                            <div className='faq-card-content'>
                                <p>{e.a}</p>
                            </div>
                        </div>
                    })
                }

            </div>
        </div>
    )
}

export default FAQ