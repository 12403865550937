export const faqContent = [
  {
    id: 1,
    q: 'What is Surplus?',
    a: 'Surplus is a platform that helps you auto-track your expenses and get tailored recommendations to help you make smarter transactions! This ensures you maximise your savings and minimise your expenses.',
  },
  {
    id: 2,
    q: 'How does Surplus help me manage my money?',
    a: 'Surplus helps you auto-track all your expenses, enabling you to make smarter transactions. We assist you in managing your recurring bills, provide key insights on your spending, and offers exclusive trial packs/discounts for apps that align with your spending patterns.',
  },
  {
    id: 3,
    q: 'Is my financial data secure when using Surplus?',
    a: 'Surplus utilises AES 256 encryption to securely encrypt and store your data, whether it is financial or otherwise. If you decide to delete your account with us, you also have the option to delete your data.',
  },
  {
    id: 4,
    q: 'How does Surplus auto-track my expenses?',
    a: 'Surplus will allow you to seamlessly link all your bank accounts that are live under Account Aggregator and auto-track your transactions.',
  },
];
