import React from 'react';
import heroImage from '../../images/hero-image.webp';
import './style.scss';
import { APP_URL, PLAY_URL } from '../../utils/global.strings';
import gPlay from '../../images/google_play_button.svg';
import appStore from '../../images/app_store_button.svg';

const Home = () => {
  return (
    <div className="home">
      <div>
        <div className="home-title">
          <span className="home-title-green">Minimise your spends </span>
          <br />
          <span>Maximise your savings </span> <br />
        </div>
        <div className="home-para">
          Auto track your expenses & get tailored solutions to optimise your spends!
        </div>
        <div className="d-flex gap-3 mt-5">
          <a href={PLAY_URL}>
            <img alt="footer-icon" src={gPlay} width={150} />
          </a>
          <a href={APP_URL}>
            <img alt="footer-icon" src={appStore} width={150} />
          </a>
        </div>
      </div>
      <div>
        <img src={heroImage} alt="hero-next" className="home-image" />
      </div>
    </div>
  );
};

export default Home;
