import './scss/app.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Routes from './screens/routes';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
const App = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={`${process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}`}>
      <Routes />
    </GoogleReCaptchaProvider>
  )
}

export default App;
