import React, { useState, useCallback, useEffect } from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import logo from '../../images/logo.svg'
import i1 from '../../images/icons/handshake.png'
import i2 from '../../images/icons/tick.png'
import i3 from '../../images/icons/public.png'
import './styles.scss'
import { postAPI } from '../../libs/api'
import {
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';




const PartnerPage = () => {
    const [formData, setFormData] = useState({})
    const [isLoading, setLoading] = useState(false)
    const handleChange = (key, value) => {
        setFormData({ ...formData, [key]: value })
    }

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = async () => {
        setLoading(true)

        const token = await executeRecaptcha();
        const apiData={
            ...formData,
            token: token
        }
        console.log(apiData)

        postAPI(`/partner`, apiData)
            .then(res => {
                setLoading(false)
                setFormData({})
                document.getElementById('partner-form').reset()
                alert("Thanks, someone will reach out to you soon.")
                window.location.reload();
            }).catch(err => {
                setLoading(false)
                console.log(err)
                setFormData({})
                document.getElementById('partner-form').reset()
            })
    }




    return (
        <div className='partner-page container'>
            <Header isPartner={true} />

            <div className='partner-page-content'>
                <div>

                    <img className='partner-page-logo' alt="brand-logo" src={logo} width={150} />
                    <h1 className='partner-page-head'>Partner with Surplus</h1>
                    {process.env.CAPTCHA_KEY}
                    <div className='partner-page-info'>
                        <div className='partner-page-info-card'>
                            <img alt='icon' src={i3} />
                            <p>More Customers</p>
                        </div>
                        <div className='partner-page-info-card'>
                            <img alt='icon' src={i1} />
                            <p>Faster Conversion</p>
                        </div>
                        <div className='partner-page-info-card'>
                            <img alt='icon' src={i2} />
                            <p>Better Retention</p>
                        </div>
                    </div>
                </div>
                <div>
                    <form className='partner-page-form' id='partner-form' onSubmit={e => {
                        e.preventDefault()
                        handleSubmit()
                    }}>
                        <h3 className='partner-page-form-head'>Let's Collaborate</h3>
                        <div className='partner-page-form-row'>
                            <label className='partner-page-form-label'>Brand Name*</label>
                            <input className='partner-page-form-input' required={true} value={formData.brand} onChange={e => {
                                handleChange("brand", e.target.value)
                            }} />
                        </div>
                        <div className='partner-page-form-row'>
                            <label className='partner-page-form-label'>POC Name*</label>
                            <input className='partner-page-form-input' required={true} value={formData.poc} onChange={e => {
                                handleChange("poc", e.target.value)
                            }} />
                        </div>
                        <div className='partner-page-form-row'>
                            <label className='partner-page-form-label'>Phone Number*</label>
                            <input className='partner-page-form-input' required={true} value={formData.mobileNumber} onChange={e => {
                                handleChange("mobileNumber", e.target.value)
                            }} />
                        </div>
                        <div className='partner-page-form-row'>
                            <label className='partner-page-form-label'>Email*</label>
                            <input className='partner-page-form-input' required={true} value={formData.email} onChange={e => {
                                handleChange("email", e.target.value)
                            }} />
                        </div>
                        <div className='partner-page-form-row'>
                            <label className='partner-page-form-label'>Website URL*</label>
                            <input className='partner-page-form-input' required={true} value={formData.website} onChange={e => {
                                handleChange("website", e.target.value)
                            }} />
                        </div>
                        <div className='partner-page-form-row'>
                            <label className='partner-page-form-label'>Add a Note</label>
                            <input className='partner-page-form-input' required={true} value={formData.note} onChange={e => {
                                handleChange("note", e.target.value)
                            }} />
                        </div>
                        <div>
                        </div>


                        <button className='partner-page-form-btn' type='submit'>
                            {isLoading ? 'loading...' : 'Submit'}
                        </button>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PartnerPage
